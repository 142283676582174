import { Toggle, ToggleChoice } from '@mit/hui'
import React from 'react'
import { Controller } from 'react-hook-form'

interface ToggleProps {
  id: string
  control?: any
  required?: boolean
  errors?: any

  choice: ToggleChoice
  name?: string
  onClick?: any
  isStatic?: boolean
  onStateChanged?: any
  showBothOptions?: boolean
  inverse?: boolean // swap positive and negative colors
  inverseLabels?: boolean
  positiveLabel?: string // only for options that show labels
  negativeLabel?: string // only for options that show labels
  neutralLabel?: string
  disabled?: boolean
}

export const ToggleWithContainer: React.FC<ToggleProps> = ({
  id,
  control,
  required,
  errors,
  choice,
  name,
  onClick,
  isStatic,
  onStateChanged,
  showBothOptions,
  inverse,
  inverseLabels,
  positiveLabel,
  negativeLabel,
  neutralLabel,
  disabled
}) => {
  return (
    <Controller
      name={id}
      control={control}
      as={
        <Toggle
          choice={choice}
          name={name}
          onClick={onClick}
          static={isStatic}
          onStateChanged={(e: any) => {
            onStateChanged?.(e)
          }}
          showBothOptions={showBothOptions}
          inverse={inverse}
          inverseLabels={inverseLabels}
          positiveLabel={positiveLabel}
          negativeLabel={negativeLabel}
          neutralLabel={neutralLabel}
          disabled={disabled}
        />
      }
      defaultValue={choice}
      rules={{ required: required }}
      error={errors}
    />
  )
}

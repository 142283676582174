import { AtlasConfigResponse } from 'Types'
import { usePrimaryRegionConfig } from '../authorization'

export class RegionController {
  readonly regionHost: string

  constructor () {
    const config = usePrimaryRegionConfig()
    this.regionHost = config.regionDomain
  }

  async getActiveRegion (): Promise<AtlasConfigResponse | null> {
    // let response = await this.useFetch('GET', 'activeRegion', `https://get.atlas-config-dev.mit.edu?key=active-region`)
    const isLocal = window.location.hostname === 'localhost'
    if (isLocal) return null

    const url = `https://${this.regionHost}?key=active-region`
    const response = await fetch(url)

    if (response.status === 200) {
      const data = response.json()
      return await data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}

// eslint-disable
import appConfig from '../../app-config'
import { AtlasConfigResponse, RegionalConfig } from 'Types'

export const usePrimaryRegionConfig = (): RegionalConfig => {
  const regionConfig: AtlasConfigResponse = JSON.parse(sessionStorage.getItem('region_config') as string)
  if (regionConfig === null) {
    console.error('No Regional Config Loaded. Default to Primary Region')
    return (appConfig.regions[0] as Record<string, any>) as RegionalConfig
  }

  const activeRegion: string = regionConfig['active.region']
  const isPrimaryRegion: boolean = activeRegion === 'primary'

  // eslint-disable-next-line
  let config: any =
    appConfig.regions.length > 0
      ? appConfig.regions.filter(region => (isPrimaryRegion ? region.isPrimaryRegion : !region.isPrimaryRegion))[0]
      : appConfig.regions[0]

  config.amplify.oauth.domain = regionConfig[isPrimaryRegion ? 'primary.authentication' : 'secondary.authentication']
  config.api.hostname = `https://${regionConfig[isPrimaryRegion ? 'primary.api' : 'secondary.api']}`
  console.log(config)
  return (config as Record<string, any>) as RegionalConfig
}

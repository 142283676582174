import React, { useEffect, useRef } from 'react'

import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'

import { AtlasPage, BasicLayout, Loader, NavBar, NavBarTypes, NavItemProps, NavItemState } from '@mit/hui'

import { LoggedInUser } from 'Components/nav'
import { getEnv } from 'Common'
import { AuthProvider } from 'Api/authorization'

import { useStoreActions, useStoreState } from 'Store/hooks'
import ExclusionListView from './Components/ExclusionListView'
import { WorkPartnersFeedHistory } from 'Components/WorkPartnersFeedHistory'
import { UnauthorizedView } from './Components/UnauthorizedView'

const App: React.FC = () => {
  const { isLoading } = useStoreState(store => store.globalModel)
  const { setIsLoading } = useStoreActions(actions => actions.globalModel)
  const isAuthenticated = useRef(false)
  const mainTabs: NavItemProps[] = [
    {
      icon: '',
      iconOnly: false,
      text: 'Workpartners Outbound Feed',
      to: '/wp-feeds',
      state: window.location.pathname.endsWith('/wp-feeds') ? NavItemState.Active : NavItemState.None
    },
    {
      icon: '',
      iconOnly: false,
      text: 'Pernr Excluded List',
      to: '/exclusion',
      state: window.location.pathname.endsWith('/exclusion') ? NavItemState.Active : NavItemState.None
    }
  ]

  useEffect(() => {
    const authProvider = new AuthProvider()
    // CHECK IF USER IS IN MOIRA LIST OF PEOPLE ALLOWED TO ACCESS THIS APP
    authProvider
      .getAuthorization()
      .then(result => {
        if (result.status === 200) {
          isAuthenticated.current = true
          setIsLoading(false)
        } else {
          isAuthenticated.current = false
          setIsLoading(false)
        }
      })
      .catch(error => {
        isAuthenticated.current = false
        setIsLoading(false)
        console.log(error)
      })
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <Loader contactEmail='help@mit.edu' hasAccess name='HRIS Feed Admin' />

  if (isAuthenticated.current) {
    return (
      <Router>
        <AtlasPage
          contentAriaLabel='Main Page'
          gaId=''
          theme='medical'
          environment={getEnv()}
          name=''
          profile={<LoggedInUser />}
          content={
            <>
              <Switch>
                <Route path='/wp-feeds'>
                  <BasicLayout
                    navigation={<NavBar type={NavBarTypes.Tabs} tabs={mainTabs} />}
                    contentTitle='Workpartners Outbound Feed'
                    startState
                    content={<WorkPartnersFeedHistory />}
                  />
                </Route>
                <Route path='/exclusion'>
                  <BasicLayout
                    navigation={<NavBar type={NavBarTypes.Tabs} tabs={mainTabs} />}
                    contentTitle='Pernr Excluded List'
                    startState
                    content={<ExclusionListView />}
                  />
                </Route>
                <Route path='/'>
                  <Redirect to='/wp-feeds' />
                </Route>
              </Switch>
            </>
          }
        />
      </Router>
    )
  } else {
    return (
      <Router>
        <AtlasPage
          contentAriaLabel='Main Page'
          gaId=''
          theme='medical'
          environment={getEnv()}
          name=''
          profile={<LoggedInUser />}
          content={
            <>
              <Switch>
                <Route path='/unauthorized'>
                  <BasicLayout contentTitle='Unauthorized' startState content={<UnauthorizedView />} />
                </Route>
                <Route path='/'>
                  <Redirect to='/unauthorized' />
                </Route>
              </Switch>
            </>
          }
        />
      </Router>
    )
  }
}

export default App

import { Alert, Form, FormContainer, FormField, GridContainer, Label, LayoutColumn, TemplateTwoColumns, Text } from '@mit/hui'
import React, { useState } from 'react'
import { ToggleWithContainer } from './ToggleWithController'

interface ExportFormProps {
  onCancel?: (e: any) => void
  onSubmit?: (e: any) => void
}

export const ExportForm: React.FC<ExportFormProps> = ({ onCancel, onSubmit }) => {
  const [showError, setShowError] = useState(false)
  const [errorFields, setErrorFields] = useState('')

  return (
    <Form>
      {methods => {
        // methods.reset = (e: any) => {
        //     console.log('Resetting fields.', e);
        // }

        return (
          <>
            {showError && <Alert text={`The following fields are missing: ${errorFields}`} type='error' />}
            <FormContainer
              methods={methods}
              formValidationTrigger='onChange'
              submitText='Execute'
              id='exportSubmission'
              action={(e, valid) => {
                const missingFields: string[] = []
                if (e.storeSftp === 'NotSelected') {
                  missingFields.push('Send file to Workpartners')
                }
                if (e.testMode === 'NotSelected') {
                  missingFields.push('Test Mode')
                }
                if (e.mitid !== '' && e.pernr !== '') {
                  missingFields.push('Both Mit ID and Pernr specified.')
                }

                if (missingFields.length > 0) {
                  setErrorFields(missingFields.join(', '))
                  setShowError(true)
                } else {
                  setShowError(false)

                  if (valid) {
                    console.log('About to submit the following: ' + JSON.stringify(e))
                    onSubmit?.(e)
                  }
                }
              }}
              secondaryAction={(e: any) => {
                console.log('Values = ' + JSON.stringify(methods.getValues()))
                console.log('Clicked on Secondary Action: ', e)
                methods.reset({
                  pernr: '',
                  rounding: 'Arithmetic Round',
                  notification_email: '',
                  export_date: new Date().toISOString().slice(0, 10),
                  storeSftp: 'NotSelected',
                  testMode: 'NotSelected'
                })
              }}
              secondaryActionText='Clear'
              secondaryActionDisabled={false}
              secondaryActionIsBusy={false}
              tertiaryAction={(e: any) => {
                onCancel?.(e)
              }}
              tertiaryActionText='Cancel'
              tertiaryActionIsBusy={false}
              tertiaryActionDisabled={false}
            >
              <GridContainer showGutters={false}>
                <LayoutColumn colSize={8}>
                  <FormField id='pernr' editor='textbox' label='Pernr' labelDisplay='nextto' />

                  <FormField id='mitid' editor='textbox' label='Mit ID' labelDisplay='nextto' />

                  <FormField
                    id='export_date'
                    editor='calendar'
                    label='Export Date'
                    isRangeSelected={false}
                    isMultiSelect={false}
                    shouldHighlightWeekends
                    placeholder='Select a date'
                    theme='Pink'
                    labelDisplay='nextto'
                    required
                    value={new Date().toISOString().slice(0, 10)}
                  />

                  <FormField
                    id='rounding'
                    editor='dropdown'
                    options={[
                      { icon: '', text: 'Arithmetic Round', id: 'ROUND' },
                      { icon: '', text: 'Up', id: 'UP' },
                      { icon: '', text: 'Down', id: 'DOWN' }
                    ]}
                    label='Rounding Mode'
                    labelDisplay='nextto'
                    value='Arithmetic Round'
                    required
                  />

                  <FormField id='notification_email' editor='textbox' label='Notification Email' labelDisplay='nextto' />

                  <TemplateTwoColumns
                    leftAlignment='right'
                    leftArea={<Label text='Send file to WorkPartners' target='storeSftp' />}
                    rightAlignment='left'
                    rightArea={
                      <ToggleWithContainer
                        control={methods.control}
                        id='storeSftp'
                        showBothOptions
                        positiveLabel='Yes'
                        negativeLabel='No'
                        choice='NotSelected'
                        required
                      />
                    }
                    padded={false}
                    primarySize={4}
                    showDivider={false}
                    showGutters
                  />

                  <TemplateTwoColumns
                    leftAlignment='right'
                    leftArea={
                      <div>
                        <Text content='Update History Tables' />
                      </div>
                    }
                    rightAlignment='left'
                    rightArea={
                      <ToggleWithContainer
                        control={methods.control}
                        id='testMode'
                        showBothOptions
                        positiveLabel='Yes'
                        negativeLabel='No'
                        choice='NotSelected'
                        required
                      />
                    }
                    padded={false}
                    primarySize={4}
                    showDivider={false}
                    showGutters
                  />
                </LayoutColumn>
              </GridContainer>
            </FormContainer>
            {/* <Spacer size={'2'}/> */}
            {/* <GridContainer>
                            <LayoutColumn colSize={2} alignment={'center'}>
                                <Button
                                    icon={'check'}
                                    text={'Submit'}
                                    onClick={() => {
                                        console.log('Submitting the form')
                                        methods.handleSubmit((e) => {
                                            console.log('Submission -- ' + e)
                                        })
                                    }}
                                />
                            </LayoutColumn>
                            <LayoutColumn colSize={2} alignment={'center'}>
                                <Button
                                    icon={'trash'}
                                    text={'Clear'}
                                    onClick={() => methods.reset()}
                                />
                            </LayoutColumn>
                        </GridContainer> */}
          </>
        )
      }}
    </Form>
  )
}

import React from 'react'

export const LinkView: React.FC<{ links: { [name: string]: string } }> = ({ links }) => {
  let reportLocation
  if (links.report_location !== undefined && links.report_location !== '') {
    reportLocation = links.report_location
  } else {
    reportLocation = undefined
  }

  let exportLocation
  if (links.export_location !== undefined && links.export_location !== '') {
    exportLocation = links.export_location
  } else {
    exportLocation = undefined
  }

  let errorLocation
  if (links.error_location !== undefined && links.error_location !== '') {
    errorLocation = links.error_location
  } else {
    errorLocation = undefined
  }

  return (
    <>
      {reportLocation !== undefined && (
        <p>
          <span>Report:&nbsp;</span>
          <a href={`ssh://saparchive.mit.edu${reportLocation}`}>{reportLocation}</a>
        </p>
      )}
      {exportLocation !== undefined && (
        <p>
          <span>Export:&nbsp;</span>
          <a href={`ssh://saparchive.mit.edu${exportLocation}`}>{exportLocation}</a>
        </p>
      )}
      {errorLocation !== undefined && (
        <p>
          <span>Errors:&nbsp;</span>
          <a href={`ssh://saparchive.mit.edu${errorLocation}`}>{errorLocation}</a>
        </p>
      )}
    </>
  )
}

import { Icon } from '@mit/hui'
import React from 'react'

export const UnauthorizedView: React.FC = () => {
  return (
    <div className='container'>
      <h1 className='text-center'>
        <Icon icon='exclamation-triangle' type='regular' />
      </h1>
      <h3 className='text-center'>You do not have permission to access this page</h3>
    </div>
  )
}

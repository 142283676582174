import {
  Button,
  ButtonBar,
  ButtonBarType,
  ButtonProps,
  ButtonState,
  ButtonType,
  Drawer,
  DrawerSize,
  DrawerType,
  TemplateTwoColumns,
  Text,
  TextType,
  TemplateModal,
  TemplateModalAlignment,
  ActionList,
  ListItemProps,
  ToastContainer,
  ToastPosition,
  ToastProps
} from '@mit/hui'
import { BadgeProps } from '@mit/hui/build/components/Badge/Badge'

import { ExclusionListController } from 'Api/controller/ExclusionListController'
import React, { useEffect, useState } from 'react'
import { HistoryItem } from 'Types'
import { SubmissionData } from 'Types/submissionData'
import { ExportForm } from './ExportForm'
import { HistoryDetail } from './HistoryDetail'
import { SubmissionConfirmation } from './SubmissionConfirmation'

export const WorkPartnersFeedHistory: React.FC = () => {
  const emptyhistoryList: ListItemProps[] = []
  const emptyItem: HistoryItem = {
    start_date: '',
    end_date: '',
    status: '',
    message: '',
    run_type: '',
    parameters: {},
    links: {}
  }
  const emptyHistoryItemList: HistoryItem[] = []
  const emptyToastList: ToastProps[] = []

  const [showNewExport, setShowNewExport] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [submissionData, setSubmissionData] = useState<SubmissionData>({
    export_date: '',
    notification_email: '',
    pernr: '',
    rounding: '',
    storeSftp: '',
    testMode: ''
  })
  const [exclusionCount, setExclusionCount] = useState('')
  const [historyLoaded, setHistoryLoaded] = useState(false)
  const [historyData, setHistoryData] = useState(emptyhistoryList)
  const [selectedItem, setSelectedItem] = useState(emptyItem)
  const [showDetails, setShowDetails] = useState(false)
  const [rawHistoryData, setRawHistoryData] = useState(emptyHistoryItemList)
  const [toasts, setToasts] = useState(emptyToastList)

  useEffect(() => {
    const exclusionListController: ExclusionListController = new ExclusionListController()

    console.log('Calling API to get Exclusion count')
    exclusionListController
      .getExclusionList()
      .then(response => {
        setExclusionCount(String(response.pernrList.length))
      })
      .catch(() => {
        console.log('Error getting the exclusion count')
      })
  }, [])

  useEffect(() => {
    const exclusionListController: ExclusionListController = new ExclusionListController()

    console.log('Calling API to get the Feed History')
    exclusionListController
      .getFeedHistory()
      .then(response => {
        response.history_items.sort((left, right) => {
          return (right.timestamp ?? 0) - (left.timestamp ?? 0)
        })
        setRawHistoryData(response.history_items)
        const historyElements = convertToListItemProps(response.history_items)
        console.log('Feed History Loaded', JSON.stringify(response, undefined, 2))

        setHistoryData(historyElements)
        setHistoryLoaded(true)
      })
      .catch(() => {
        console.log('Error getting the Feed History')
      })
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshHistory()
    }, 30000)

    return () => {
      clearInterval(intervalId)
    }
  })

  const convertToListItemProps = (items: HistoryItem[]): ListItemProps[] => {
    const historyElements = items.map(
      (element: HistoryItem, idx): ListItemProps => {
        const badge: BadgeProps =
          element.status === 'Success'
            ? {
              type: 'success',
              content: element.status
            }
            : {
              type: 'danger',
              content: element.status ?? ''
            }

        return {
          text: element.end_date ?? '',
          badge: badge,
          secondaryText: element.run_type,
          tertiaryText: element.message === '' ? '' : element.message,
          singleLine: false,
          onClick: (event: React.MouseEvent<Element, MouseEvent>, item: ListItemProps) => {
            setSelectedItem(element)
            setShowDetails(true)
            console.log(JSON.stringify(item))
            event.preventDefault()
          }
        }
      }
    )

    return historyElements
  }

  const addToRawHistoryList = (items: HistoryItem[]): void => {
    const currentIds: string[] = rawHistoryData.map(item => {
      return item.id ?? ''
    })

    const newItems = items.filter(element => {
      return !currentIds.includes(element.id ?? '')
    })

    console.log(`Size of newItems = ${newItems.length}`)

    Array.prototype.push.apply(rawHistoryData, newItems)
  }

  const openNewExport = (): void => {
    setShowNewExport(true)
  }

  const refreshHistory = (): void => {
    console.log('Refreshing the history list.')

    const exclusionListController: ExclusionListController = new ExclusionListController()

    console.log('Calling API to get the Feed History')
    exclusionListController
      .getFeedHistory()
      .then(response => {
        console.log('Updated Feed List received')
        addToRawHistoryList(response.history_items)

        rawHistoryData.sort((left, right) => {
          return (right.timestamp ?? 0) - (left.timestamp ?? 0)
        })

        console.log('RawHistoryList = ', JSON.stringify(rawHistoryData, undefined, 2))

        const historyElements = convertToListItemProps(rawHistoryData)

        setHistoryData(historyElements)
      })
      .catch(() => {
        console.log('Error getting the Feed History')
      })
  }

  const confirmButtons: ButtonProps[] = [
    {
      icon: '',
      text: 'Confirm',
      onClick: (e: any) => {
        console.log('Perform Export with the following: ' + JSON.stringify(submissionData))
        new ExclusionListController()
          .performExport(submissionData)
          .then(() => {
            setShowModal(false)
            setShowNewExport(false)

            const newToasts = [...toasts]
            newToasts.push({
              icon: {
                icon: 'check-circle',
                type: 'solid'
              },
              title: 'Success',
              message: 'New run succesfully started',
              show: true,
              type: 2,
              onClose: () => {
                setToasts([])
              }
            })

            setToasts(newToasts)
          })
          .catch(error => {
            console.log('Error starting the export: ', error)
            setShowModal(false)
            setShowNewExport(false)

            const data = JSON.parse(error)

            let message: string = ''
            if (typeof data.message === 'string') {
              message = data.message
            }

            const newToasts = [...toasts]
            newToasts.push({
              icon: {
                icon: 'exclamation-triangle',
                type: 'solid'
              },
              title: 'Unable to start run',
              message: `Error starting the new run: ${message}`,
              show: true,
              type: 1,
              onClose: () => {
                setToasts([])
              }
            })

            setToasts(newToasts)
          })
      },
      type: ButtonType.Primary,
      state: ButtonState.Enabled
    },
    {
      icon: '',
      text: 'Cancel',
      onClick: (e: any) => {
        setShowModal(false)
      },
      type: ButtonType.Primary,
      state: ButtonState.Enabled
    }
  ]

  return (
    <>
      <ToastContainer position={ToastPosition.TopCenter} toasts={toasts} />
      <TemplateTwoColumns
        leftAlignment='left'
        leftArea={<Text type={TextType.Heading1} content='Workpartners Outbound Feed History' />}
        rightAlignment='right'
        rightArea={
          <>
            <Button text='Refresh' onClick={refreshHistory} />
            <span>&nbsp;</span>
            <Button text='New Run' onClick={openNewExport} />
          </>
        }
        padded={false}
        primarySize={9}
        showDivider={false}
        showGutters={false}
      />

      <ActionList items={historyData} isLoading={!historyLoaded} />

      <Drawer
        contentAriaLabel='show-detail'
        onClose={() => {
          setShowDetails(false)
        }}
        show={showDetails}
        contents={<HistoryDetail item={selectedItem} />}
        type={DrawerType.Right}
        header={<Text type={TextType.Heading4} content='Workpartners Outbound Feed History' />}
        size={DrawerSize.Medium}
        footer={[]}
      />

      <Drawer
        contentAriaLabel='perform-export'
        onClose={(e: any) => setShowNewExport(false)}
        show={showNewExport}
        contents={
          <ExportForm
            onCancel={(e: any) => setShowNewExport(false)}
            onSubmit={(e: any) => {
              setShowModal(true)
              setSubmissionData(e)
            }}
          />
        }
        type={DrawerType.Right}
        header={<Text type={TextType.Heading1} content='New Run for Workpartners Outbound Feed' />}
        size={DrawerSize.Medium}
        footer={[]}
      />
      <TemplateModal
        name='confirmation'
        show={showModal}
        noExternalClose
        // onClose={() => {
        //     setShowModal(false)
        //     setShowNewExport(false)
        //     console.log('Closing Confirmation')
        // }}
        header={<Text type={TextType.Heading4} content='Confirm Submission' />}
        footer={<ButtonBar type={ButtonBarType.Padded} buttons={confirmButtons} />}
        body={<SubmissionConfirmation submissionData={submissionData} exclusionCount={exclusionCount} />}
        bodyAlignment={TemplateModalAlignment.Left}
      />
    </>
  )
}

import Amplify, { Auth } from 'aws-amplify'
import { FederatedSignInOptionsCustom } from '@aws-amplify/auth/lib/types'
import { usePrimaryRegionConfig } from 'Api/authorization/RegionConfig'
import { AmplifyTokenResponse, RegionalConfig } from 'Types'
import {AuthorizationController} from "../controller";

export class AuthProvider {
  authorizationController = new AuthorizationController();

  static configure = async (): Promise<void> => {
    const config: RegionalConfig = usePrimaryRegionConfig()
    Amplify.configure(config.amplify)
  }

  getToken = async (): Promise<AmplifyTokenResponse> => {
    const config: RegionalConfig = usePrimaryRegionConfig()
    return await new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(res => {
          console.log('logged in', res)
          // resolve(res.getSignInUserSession().accessToken.jwtToken);
          // debugger;
          resolve({
            token: res.getSignInUserSession().accessToken.jwtToken,
            user: res
              .getUsername()
              .split('Touchstone_')[1]
              .split('@')[0]
          })
        })
        .catch(err => {
          console.log('not logged in', err)
          const options: FederatedSignInOptionsCustom = {
            customProvider: config.signInProvider
          }
          Auth.federatedSignIn(options).catch(err1 => {
            console.log('login err', err1)
          })
        })
    })
  }


  getAuthorization = async (): Promise<any> => {
    return await this.authorizationController.isAuthorized()
  }

  logout = async (): Promise<void> => {
    console.debug('calling Auth.signOut()')
    await Auth.signOut()
  }
}

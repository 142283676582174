import { Action, action } from 'easy-peasy'

export interface State {
  auth: object
  isLoading: boolean
}
export interface Actions {
  setAuth: Action<this, object>
  setIsLoading: Action<this, boolean>
}
// eslint-disable-next-line
export interface Thunks {}

export interface GlobalModel extends State, Actions, Thunks {}

export const globalModel: GlobalModel = {
  auth: {},
  isLoading: true,

  // Actions
  setAuth: action((state, payload) => {
    state.auth = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  })
}

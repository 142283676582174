import { ExclusionList, FeedHistory } from 'Types'
import { SubmissionData } from 'Types/submissionData'
import { BaseController } from './BaseController'

export class ExclusionListController extends BaseController {
  async getExclusionList (): Promise<ExclusionList> {
    const response = await this.useFetch('GET', 'exclusionList', `${this.apiHost}/${this.apiPathPfmla}/excluded_pernrs`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async removePernr (pernr: string): Promise<string> {
    const body = {
      pernrList: [pernr]
    }

    const response = await this.useFetch('POST', 'removeExclusionList', `${this.apiHost}/${this.apiPathPfmla}/remove_excluded_pernrs`, body)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async addPernr (pernr: string): Promise<string> {
    const body = {
      pernrList: [pernr]
    }

    const response = await this.useFetch('POST', 'addExclusionList', `${this.apiHost}/${this.apiPathPfmla}/excluded_pernrs`, body)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async clearPernrList (): Promise<string> {
    const response = await this.useFetch('POST', 'clearExclusionList', `${this.apiHost}/${this.apiPathPfmla}/clear_excluded_pernrs`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  async getFeedHistory (): Promise<FeedHistory> {
    const response = await this.useFetch('GET', 'feedHistory', `${this.apiHost}/${this.apiPathPfmla}/history`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.json().message
      return await Promise.reject(text)
    }
  }

  async performExport (submissionData: SubmissionData): Promise<string> {
    let exportdate: string = ''

    if (submissionData.export_date != null && submissionData.export_date !== '') {
      const dateComps = submissionData.export_date.split('/')
      exportdate = `${dateComps[2]}-${dateComps[0]}-${dateComps[1]}`
    }

    let rounding: string = 'ROUND'
    switch (submissionData.rounding) {
      case 'Arithmetic Round':
        rounding = 'ROUND'
        break
      case 'Up':
        rounding = 'UP'
        break
      case 'Down':
        rounding = 'DOWN'
        break
      default:
        rounding = 'ROUND'
        break
    }

    const body = {
      pernr: submissionData.pernr,
      mitid: submissionData.mitid,
      exportdate: exportdate,
      rounding: rounding,
      storeSftp: submissionData.storeSftp === 'Positive' ? 'true' : 'false',
      testRun: submissionData.testMode === 'Negative' ? 'true' : 'false',
      notification_email: submissionData.notification_email
    }

    console.log('Calling full_eligibility with: ' + JSON.stringify(body))

    const response = await this.useFetch('POST', 'performExport', `${this.apiHost}/${this.apiPathPfmla}/full_eligibility`, body)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }
}

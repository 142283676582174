import { CounterModel, counterModel } from './counter'
import { GlobalModel, globalModel } from './global'
import { ProfileModel, profileModel } from './profile'

export interface Model {
  counterModel: CounterModel
  globalModel: GlobalModel
  profileModel: ProfileModel
}

export const model: Model = {
  counterModel,
  globalModel,
  profileModel
}

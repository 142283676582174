import { TemplateTwoColumns, Text, TextType } from '@mit/hui'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { HistoryItem } from 'Types'
import { LinkView } from './LinkView'
import { ParameterView } from './ParameterView'

export const HistoryDetail: React.FC<{ item: HistoryItem }> = ({ item }) => {
  const showDivider = true
  const elementSize = 5

  return (
    <>
      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='Start Date' />}
        rightAlignment='left'
        rightArea={<Text type={TextType.Body} content={item.start_date ?? ''} />}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />

      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='End Date' />}
        rightAlignment='left'
        rightArea={<Text type={TextType.Body} content={item.end_date ?? ''} />}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />

      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='Run Status' />}
        rightAlignment='left'
        rightArea={<Text type={TextType.Body} content={item.status ?? ''} />}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />

      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='Message' />}
        rightAlignment='left'
        rightArea={<Text type={TextType.Body} content={item.message ?? ''} />}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />

      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='Additional Information' />}
        rightAlignment='left'
        rightArea={<ReactMarkdown>{item.additional_info ?? ''}</ReactMarkdown>}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />

      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='Run Type' />}
        rightAlignment='left'
        rightArea={<Text type={TextType.Body} content={item.run_type ?? ''} />}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />

      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='Username' />}
        rightAlignment='left'
        rightArea={<Text type={TextType.Body} content={item.username ?? ''} />}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />

      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='Run Parameters' />}
        rightAlignment='left'
        rightArea={<ParameterView parameters={item.parameters ?? {}} />}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />

      <TemplateTwoColumns
        leftAlignment='right'
        leftArea={<Text type={TextType.Body} content='Links' />}
        rightAlignment='left'
        rightArea={<LinkView links={item.links ?? {}} />}
        showDivider={showDivider}
        showGutters
        padded={false}
        primarySize={elementSize}
      />
    </>
  )
}

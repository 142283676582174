import { Icon, TemplateTwoColumns, Text, TextType } from '@mit/hui'
import React from 'react'

export const ParameterView: React.FC<{ parameters: { [name: string]: string | boolean } }> = ({ parameters }) => {
  return (
    <>
      {Object.keys(parameters).map((element, idx) => {
        let valueElement
        if (typeof parameters[element] === 'boolean') {
          valueElement = (
            <Icon
              type='solid'
              icon={parameters[element] === true ? 'check-circle' : 'times-circle'}
              color={parameters[element] === true ? 'green' : 'red'}
            />
          )
        } else {
          valueElement = <Text type={TextType.Body} content={String(parameters[element])} />
        }

        return (
          <TemplateTwoColumns
            key={`param-${idx}`}
            leftAlignment='left'
            leftArea={<Text type={TextType.Body} content={element} />}
            rightAlignment='left'
            rightArea={valueElement}
            showDivider={false}
            padded={false}
            primarySize={3}
            showGutters={false}
          />
        )
      })}
    </>
  )
}

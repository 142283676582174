import { AuthProvider, usePrimaryRegionConfig } from '../authorization'

const appConfig = usePrimaryRegionConfig()

export class BaseController {
  readonly apiHost: string
  readonly apiPath: string
  readonly apiPathDigitalId: string
  readonly apiPathPfmla: string

  fetchController: Record<string, any>

  constructor () {
    this.apiHost = appConfig.api.hostname
    this.apiPath = appConfig.api.paths.elo
    this.apiPathDigitalId = appConfig.api.paths['digital-id']
    this.apiPathPfmla = appConfig.api.paths.pfmla
    this.fetchController = {}
  }

  async getHeaders (method: string, file: boolean = false, impersonateKerb: string = ''): Promise<Headers> {
    const tokenResponse = await new AuthProvider().getToken()
    const token: string = tokenResponse.token

    const headers: Headers = new Headers()

    if (!file) {
      headers.append('Content-Type', 'application/json')
      headers.append('Authorization', 'Bearer ' + token)
    }

    if (impersonateKerb !== '') {
      headers.append('X-Impersonated-User', impersonateKerb)
    }

    return headers
  }

  async useFetch (
    method: string,
    fetchKey: string,
    url: string,
    body: any = null,
    file: boolean = false,
    impersonateKerb: string = ''
  ): Promise<any> {
    if (this.fetchController[fetchKey] !== null && this.fetchController[fetchKey] !== undefined) {
      this.fetchController[fetchKey].abort()
    }

    this.fetchController[fetchKey] = new AbortController()
    const { signal } = this.fetchController[fetchKey]

    const options: any = {
      method: method,
      headers: await this.getHeaders(method, file, impersonateKerb),
      signal
    }
    if (body !== null) {
      options.body = file ? body : JSON.stringify(body)
    }

    const response = await fetch(url, options)

    // clear out controller
    this.fetchController[fetchKey] = null

    return response
  }

  async logout (): Promise<void> {
    await new AuthProvider().logout()
  }

  async getUserAuth (): Promise<any> {
    const response = await this.useFetch('GET', 'initialize', `${this.apiHost}/${this.apiPath}/authorization`)

    if (response.status === 200) {
      const data = response.json()
      return data
    } else {
      const text = await response.text()
      return await Promise.reject(text)
    }
  }

  // Calls an endpoint that checks if the current user exists in LDAP
  async getLDAPAuth (): Promise<any> {
    return await this.useFetch('GET', 'initialize', `${this.apiHost}/${this.apiPathPfmla}/initialize`)
  }
}

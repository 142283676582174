import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './scss/main.scss'

import { StoreProvider } from 'easy-peasy'
import { Loader, MITLoggedOut } from '@mit/hui'
import { store } from 'Store'
import { RegionController } from 'Api/controller'
import { AuthProvider } from 'Api/authorization'
import App from './App'

export const Initialization: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasAccess, setHasAccess] = useState<boolean>(false)

  const location = window.location
  const path = location.pathname

  useEffect(() => {
    // clear previous config
    sessionStorage.removeItem('region_config')
    const controller = new RegionController()
    controller
      .getActiveRegion()
      .then(async data => {
        sessionStorage.setItem('region_config', JSON.stringify(data))
        await AuthProvider.configure()

        // Make any auth/initialization api calls here
        setIsLoading(false)
        setHasAccess(true)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
        setHasAccess(false)
      })
  }, [])

  if (isLoading) return <Loader contactEmail='reactTemplate' hasAccess={hasAccess} name='React Template' isLoading={isLoading} />

  return (
    <Switch>
      {path !== '/logout' && <Route path='/' component={App} />}
      <Route exact path='/logout' component={MITLoggedOut} />
    </Switch>
  )
}

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <StoreProvider store={store}>
        <Initialization />
      </StoreProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
)

import {
  ActionList,
  ButtonState,
  ButtonType,
  Error,
  ErrorContext,
  ErrorType,
  Form,
  FormContainer,
  FormField,
  GridContainer,
  LayoutColumn,
  Spacer,
  TemplateThreeColumns,
  Text
} from '@mit/hui'
import { ExclusionListController } from 'Api/controller/ExclusionListController'
import React from 'react'
import { ExclusionList } from 'Types'

interface ExclusionListViewState {
  exclusionList: string[]
  exclusionListLoading: boolean
  showError: boolean
}

export default class ExclusionListView extends React.Component<{}, ExclusionListViewState> {
  exclusionListController: ExclusionListController

  constructor (props: any) {
    super(props)

    this.exclusionListController = new ExclusionListController()
    this.state = {
      exclusionList: [],
      exclusionListLoading: true,
      showError: false
    }
  }

  componentDidMount (): void {
    this.getExclusionList()
  }

  getExclusionList (): void {
    this.setState({
      exclusionListLoading: true
    })

    this.exclusionListController
      .getExclusionList()
      .then((response: ExclusionList) => {
        this.setState({
          exclusionList: response.pernrList,
          exclusionListLoading: false
        })
      })
      .catch(error => {
        console.error('Error Getting the exclusion list: ', error)
        this.setState({ showError: true })
      })
  }

  render (): JSX.Element {
    return (
      <div>
        {!this.state.exclusionListLoading && this.state.exclusionList && (
          <Text content={`Pernr Exclusion list contains ${this.state.exclusionList.length} entries`} />
        )}
        <Form>
          {methods => {
            return (
              <>
                <FormContainer
                  methods={methods}
                  formValidationTrigger='onChange'
                  submitText='Add Pernr'
                  id='addPernrForm'
                  action={(e, valid) => {
                    this.exclusionListController
                      .addPernr(e.pernr)
                      .then(response => {
                        this.getExclusionList()
                      })
                      .catch(response => {
                        console.log('Unable to Add Pernr: ', response)
                      })
                  }}
                  secondaryAction={(e: any) => {
                    this.exclusionListController
                      .clearPernrList()
                      .then(response => {
                        this.getExclusionList()
                      })
                      .catch(response => {
                        console.log('Unable to Clear the Pernr Exclusion List: ', response)
                      })
                  }}
                  secondaryActionText='Clear List'
                >
                  <GridContainer showGutters={false}>
                    <LayoutColumn colSize={2}>
                      <FormField id='pernr' editor='textbox' label='Pernr' labelDisplay='nextto' />
                    </LayoutColumn>
                  </GridContainer>
                </FormContainer>
              </>
            )
          }}
        </Form>
        <Spacer size='3' />
        {this.state.showError && (
          <Error message='Error Loading the Pernr Exclusion List' type={ErrorType.Connection} context={ErrorContext.Component} />
        )}
        {!this.state.showError && (
          <TemplateThreeColumns
            leftAlignment='left'
            leftArea={
              <ActionList
                isLoading={this.state.exclusionListLoading}
                selectable
                isEmpty={this.state.exclusionList.length === 0}
                items={this.state.exclusionList.map(itm => {
                  return {
                    text: itm,
                    actions: [
                      {
                        icon: 'trash',
                        text: '',
                        onClick: () => {
                          console.log('Removing item: ' + itm)
                          this.exclusionListController
                            .removePernr(itm)
                            .then((response: string) => {
                              this.getExclusionList()
                            })
                            .catch(response => {
                              console.log('Error Removing the item: ', response)
                            })
                        },
                        type: ButtonType.Icon,
                        state: ButtonState.Enabled
                      }
                    ],
                    onClick: (event, item) => {
                      console.log('Clicked on ', item)
                    }
                  }
                })}
              />
            }
            centerAlignment='left'
            centerArea={null}
            rightAlignment='left'
            rightArea={null}
            showGutters={false}
            showDividers={false}
            padded={false}
          />
        )}
      </div>
    )
  }
}

import { Text } from '@mit/hui'
import React from 'react'
import { SubmissionData } from 'Types/submissionData'

export const SubmissionConfirmation: React.FC<{
  submissionData: SubmissionData
  exclusionCount: string
}> = ({ submissionData, exclusionCount }) => {
  console.log('Submission Data = ' + JSON.stringify(submissionData))

  return (
    <>
      <Text content='A new feed will be generated with the following options:' />

      {submissionData.pernr != null && submissionData.pernr !== '' ? (
        <Text content={`Create feed for the following pernr: ${submissionData.pernr} `} />
      ) : (
        <Text content={`Create feed for all pernrs except ${exclusionCount} pernrs on exclusion list`} />
      )}

      {submissionData.export_date != null && submissionData.export_date !== '' ? (
        <Text content={`Date to use for feed: ${submissionData.export_date}`} />
      ) : (
        <Text content={`Date to use for feed: ${new Date().toLocaleDateString()}`} />
      )}
      <Text content={`Feed will be using the following rounding: ${submissionData.rounding ?? 'Invalid'}`} />

      {submissionData.notification_email != null && submissionData.notification_email !== '' ? (
        <Text content={`Notification will email will be send to the following email address(es): ${submissionData.notification_email}`} />
      ) : (
        <Text content='Notification will be emailed to the default email address' />
      )}

      {submissionData.storeSftp === 'Positive' && <Text color='red' content='Feed will be uploaded to Workpartners' />}
      {submissionData.storeSftp === 'Negative' && <Text content='Feed will not be uploaded to WorkPartners' />}

      {submissionData.testMode === 'Negative' && <Text color='red' content='Test Run enabled, SAP tables will not be updated' />}
      {submissionData.testMode === 'Positive' && <Text content='Test Run disabled. SAP tables will be updated' />}

      {submissionData.storeSftp === 'Positive' && submissionData.testMode === 'Negative' && (
        <Text color='red' content='Test Run and Workpartners upload has been selected. Are you sure this is correct?' />
      )}
    </>
  )
}
